<template>
  <div class="home">
    <h1>Todo List</h1>
    <div>
      <input v-model="newTodo" @keyup.enter="addTodo" placeholder="Add a new todo" />
      <button @click="addTodo">Add</button>
    </div>
    <ul>
      <li v-for="todo in todos" :key="todo.id">
        <input type="checkbox" v-model="todo.completed" />
        <span :class="{ 'completed': todo.completed }">{{ todo.text }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newTodo: '',
      todos: [
        { id: 1, text: 'Learn Vue.js', completed: false },
        { id: 2, text: 'Build a PWA', completed: false },
        { id: 3, text: 'Deploy to Netlify', completed: false },
      ],
    };
  },
  methods: {
    addTodo() {
      if (this.newTodo.trim() === '') return;
      this.todos.push({ id: this.todos.length + 1, text: this.newTodo, completed: false });
      this.newTodo = '';
    },
  },
};
</script>

<style scoped>
.completed {
  text-decoration: line-through;
}
</style>